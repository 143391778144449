// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-manchester-hotel-saunas-js": () => import("/opt/build/repo/src/pages/guide/manchester-hotel-saunas.js" /* webpackChunkName: "component---src-pages-guide-manchester-hotel-saunas-js" */),
  "component---src-pages-guide-near-shaftesbury-avenue-london-js": () => import("/opt/build/repo/src/pages/guide/near-shaftesbury-avenue-london.js" /* webpackChunkName: "component---src-pages-guide-near-shaftesbury-avenue-london-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-areswimsuitscompulsory-js": () => import("/opt/build/repo/src/pages/info/areswimsuitscompulsory.js" /* webpackChunkName: "component---src-pages-info-areswimsuitscompulsory-js" */),
  "component---src-pages-info-naked-saunas-couples-js": () => import("/opt/build/repo/src/pages/info/naked-saunas-couples.js" /* webpackChunkName: "component---src-pages-info-naked-saunas-couples-js" */),
  "component---src-pages-info-towelfriendlysauna-js": () => import("/opt/build/repo/src/pages/info/towelfriendlysauna.js" /* webpackChunkName: "component---src-pages-info-towelfriendlysauna-js" */),
  "component---src-pages-info-wikipedias-naked-facts-js": () => import("/opt/build/repo/src/pages/info/wikipedias-naked-facts.js" /* webpackChunkName: "component---src-pages-info-wikipedias-naked-facts-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

